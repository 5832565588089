import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import "@fontsource/open-sans"
import gifOne from './../images/Untitled_Artwork 5.gif'
import gifTwo from './../images/Untitled_Artwork 6.gif'
import keyboard from './../images/keyboard.png'
import { RoughNotation, RoughNotationGroup } from "react-rough-notation";
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';

const pageStyles = {
  color: "#232129",
  fontFamily: "-apple-system, Open Sans, sans-serif, serif",
  fontSize: "x-large",
  maxWidth: 600,
  marginLeft: "auto",
  marginRight: "auto",
  padding:"20px"
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
}

const listStyles = {
  marginBottom: 96,
  paddingLeft: 0,
}
const listItemStyles = {
  fontWeight: 300,
  fontSize: 24,
  maxWidth: 560,
  marginBottom: 30,
}

const linkStyle = {
  color: "#8954A8",
  fontWeight: "bold",
  fontSize: 16,
  verticalAlign: "5%",
}


const descriptionStyle = {
  color: "#232129",
  fontSize: 14,
  marginTop: 10,
  marginBottom: 0,
  lineHeight: 1.25,
}

const badgeStyle = {
  color: "#fff",
  backgroundColor: "#088413",
  border: "1px solid #088413",
  fontSize: 11,
  fontWeight: "bold",
  letterSpacing: 1,
  borderRadius: 4,
  padding: "4px 6px",
  display: "inline-block",
  position: "relative",
  top: -2,
  marginLeft: 10,
  lineHeight: 1,
}

const links = [
  {
    text: "Tutorial",
    url: "https://www.gatsbyjs.com/docs/tutorial/",
    description:
      "A great place to get started if you're new to web development. Designed to guide you through setting up your first Gatsby site.",
    color: "#E95800",
  },
  {
    text: "How to Guides",
    url: "https://www.gatsbyjs.com/docs/how-to/",
    description:
      "Practical step-by-step guides to help you achieve a specific goal. Most useful when you're trying to get something done.",
    color: "#1099A8",
  },
  {
    text: "Reference Guides",
    url: "https://www.gatsbyjs.com/docs/reference/",
    description:
      "Nitty-gritty technical descriptions of how Gatsby works. Most useful when you need detailed information about Gatsby's APIs.",
    color: "#BC027F",
  },
  {
    text: "Conceptual Guides",
    url: "https://www.gatsbyjs.com/docs/conceptual/",
    description:
      "Big-picture explanations of higher-level Gatsby concepts. Most useful for building understanding of a particular topic.",
    color: "#0D96F2",
  },
  {
    text: "Plugin Library",
    url: "https://www.gatsbyjs.com/plugins",
    description:
      "Add functionality and customize your Gatsby site or app with thousands of plugins built by our amazing developer community.",
    color: "#8EB814",
  },
  {
    text: "Build and Host",
    url: "https://www.gatsbyjs.com/cloud",
    badge: true,
    description:
      "Now you’re ready to show the world! Give your Gatsby site superpowers: Build and host on Gatsby Cloud. Get started for free!",
    color: "#663399",
  },
]

const IndexPage = () => {
  return (
    <div>
    <div style={{zIndex:"-10", position:"fixed", width:"100%", height:"100%", display: "grid",  gridTemplateColumns: "33% 33% 33%", gridTemplateRows: "300px"}}>
  <ParallaxProvider>
    <Parallax  translateX= {[`20%`, `-100%`]} rotate= {[0, 360]} scale= {[1, 0.8, 'easeInOutBack']} style={{marginLeft:"auto", marginRight:"auto"}} opacity={[0.5,0.5]}>
<StaticImage placeholder = "none"  style={{maxWidth:"200px"}}src="./../images/paint1.png"/>  </Parallax>
  <Parallax translateY= {[`-100%`, `100%`]} translateX= {[`100%`, `-800%`]} style={{marginLeft:"auto", marginRight:"auto"}} opacity={[0.5,0.5]}>
    <img src={gifOne} style={{maxWidth:"200px"}} />
  </Parallax>
  <Parallax translateY= {[`-125%`, `20%`]} scale = {[0.5,1]} translateX= {[`-300%`, `10%`]} style={{marginLeft:"auto", marginRight:"auto"}} opacity={[0.5,0.5]}>
    <img src={gifTwo} style={{maxWidth:"200px"}}/>
  </Parallax>
  <Parallax translateY= {[`-25%`, `150%`]}  translateX= {[`-100%`, `100%`]} rotate = {[0, 360]} scale= {[1, 0.7, 'easeInOutBack']} style={{marginLeft:"auto", marginRight:"auto"}} opacity={[0.5,0.5]}>
  <StaticImage placeholder = "none"  style={{maxWidth:"200px"}}src="./../images/paint2.png"/>  </Parallax>
  <Parallax translateX= {[`20%`, `-100%`]} style={{marginLeft:"auto", marginRight:"auto"}} opacity={[0.5,0.5]}>
<StaticImage placeholder = "none"  style={{maxWidth:"200px"}}src="./../images/keyboard.png"/>  </Parallax>

  <Parallax translateY={[`100%`, `-100%`, 'easeInOutQuad']} translateX={[`100%`, `-100%`]} style={{marginLeft:"auto", marginRight:"auto"}} opacity={[0.5,0.5]}>
<StaticImage placeholder = "none"  style={{maxWidth:"200px"}}src="./../images/paint3.png"/>  </Parallax>

  <Parallax translateY={[`-100%`, `100%`]} rotateY= {[0, 360]} style={{marginLeft:"auto", marginRight:"auto"}} opacity={[0.5,0.5]}>
<StaticImage placeholder = "none"  style={{maxWidth:"200px"}}src="./../images/brainscan.png"/>  </Parallax>
    </ParallaxProvider>
    </div>
    <main style={pageStyles}>

    <div>
    </div>
    <StaticImage placeholder = "none"  style={{maxWidth:"300px", marginLeft:"auto", marginRight:"auto"}}src="./../images/favicon.png" alt="Logo" />
        <div> <h2>When was your last <RoughNotation show={true} type="highlight" color="#3457D5">playdate?</RoughNotation> </h2>
    <div><p> When did we stop having <RoughNotation show={true} type="highlight" color="yellow">playdates</RoughNotation>? So many of our interactions are going out to the bar, networking at events, and <RoughNotation show={true} type="strike-through" color="black">complaining about our lives</RoughNotation> brunch, but we are missing a crucial type of interaction: <RoughNotation show={true} type="brackets" color="purple"><b>creation together.</b></RoughNotation> It used to be a fundamental part of our lives and now it isn’t.</p></div>
    <div><p>We are people who like to make stuff. Good stuff, bad stuff, lots and lots of weird stuff- it doesn’t matter. We believe that creating should be fun, art should be collaborative and failing… should be encouraged. In essence, we want to bring back the Playdate. It’s been way too long.</p></div>
    <div><p>We are people who like to make stuff. Good stuff, bad stuff, lots and lots of weird stuff- it doesn’t matter. We believe that creating should be fun, art should be collaborative and failing… should be encouraged. In essence, we want to bring back the Playdate. It’s been way too long.</p></div>
    <div><p>We are people who like to make stuff. Good stuff, bad stuff, lots and lots of weird stuff- it doesn’t matter. We believe that creating should be fun, art should be collaborative and failing… should be encouraged. In essence, we want to bring back the Playdate. It’s been way too long.</p></div>
    <div><p>We are people who like to make stuff. Good stuff, bad stuff, lots and lots of weird stuff- it doesn’t matter. We believe that creating should be fun, art should be collaborative and failing… should be encouraged. In essence, we want to bring back the Playdate. It’s been way too long.</p></div>
    <RoughNotation show={true} type="highlight" color="black"><a style={{color:"white"}}>To join, text 330-631-6125.</a></RoughNotation>
    </div>
    </main>
    </div>

  )
}

export default IndexPage

export const Head = () => <title>The Creative Institution</title>
